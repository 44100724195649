import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';


import * as endpoints from '../../shared/services/api-services/api-endpoints';
import { ApiHandler } from '../../shared/services/api-services/api-handler.service';
import { TokenManager } from '../../shared/services/api-services/token-manager.service';
// tslint:disable-next-line:max-line-length
import { loginURL, signupURL, changePasswordURL, resetPasswordURL, forgotPasswordURL, leaveMessageURL } from '../../shared/services/api-services/api-endpoints';


@Injectable()
export class AuthService {

  redirectUrl: string;

  constructor(private apiHandler: ApiHandler, private manageToken: TokenManager) { }

  isLoggedIn() {
    // TODO: need to add logic to invalidate token
    if (this.manageToken.fetchToken()) {
      return true;
    }
  }

  loginUser(email: string, password: string): Observable<any> {
    // Frame JSON body
    const loginReqBody = {
      'email': email,
      'password': password
    };

    return this.apiHandler.apiPost(loginURL(), loginReqBody).map((response: any) => {
      const data = response.data;
      const token = data.user_token.access_token;
      if (token) {
        localStorage.setItem('currentUser', JSON.stringify({
          user_type: data.user_type,
          token: token,
          name: data.name,
          id: data.id,
          status: data.status === 'active' ? true : false
        }));
      }
      return response;
    });
  }

  signupUser(name, email, password): Observable<any> {
    // Frame JSON body
    const signupReqBody = {
      'name': name,
      'email': email,
      'password': password
    };

    return this.apiHandler.apiPost(signupURL(), signupReqBody).map((response: any) => {
      return response;
    });
  }


  leaveMessage(frm): Observable<any> {
    // Frame JSON body
    let question = frm.question;
    question = question.replace(/\r?\n/g, '<br />');
    const reqBody = {
      'name': frm.name,
      'email': frm.email,
      'phone': frm.phone,
      'question': question
    };

    return this.apiHandler.apiPost(leaveMessageURL(), reqBody).map((response: any) => {
      return response;
    });
  }

  changePassword(currentPassword: string, newPassword: string) {
    const body = {
      current_password: currentPassword,
      new_password: newPassword
    };
    return this.apiHandler.apiPost(changePasswordURL(), body);
  }

  logout() {
    return this.apiHandler.apiPost(endpoints.logoutURL(), null);
  }

  forgetPassword(email: string) {
    const body = { email: email };
    return this.apiHandler.apiPost(forgotPasswordURL(), body);
  }

  resetPassword(token: string, newPassword: string) {
    const body = {
      token: token,
      password: newPassword
    };
    return this.apiHandler.apiPost(resetPasswordURL(), body);
  }
}
