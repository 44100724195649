import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { SharedModule } from './shared/module/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './chat/chat.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { TokenManager } from './shared/services/api-services/token-manager.service';
import { TokenInterceptor } from './app.interceptor';
import { ApiHandler } from './shared/services/api-services/api-handler.service';
import { ToasterService } from './shared/services/toaster.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChatService } from './shared/services/chatService.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmLeaveComponent } from './shared/component/confirm-leave/confirm-leave.component';
import { WebRtcService } from './shared/services/WebRtcService.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LeaveGuard } from './auth/services/leave-guard.service';

 // const config: SocketIoConfig = { url: 'https://tele-pharmco.com:7003/', options: {'reconnection': true} };
 const config: SocketIoConfig = { url: 'https://telepharmco-api.codiantdev.com/', options: {'reconnection': true} };

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ChatComponent,
    ConfirmLeaveComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    SharedModule,
    NgbModule.forRoot(),
    SocketIoModule.forRoot(config),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule
  ],
  providers: [
    TokenManager,
    ToasterService,
    ChatService,
    WebRtcService,
    LeaveGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
     {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ApiHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
